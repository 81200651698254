import { parseAsArrayOf, parseAsString, useQueryState } from 'next-usequerystate'
import { useCallback, useEffect, useMemo } from 'react'

import { SelectedOption } from '../constants/options'
import { QueryParam, QuerySpotOrderType } from '../constants/query'
import { DEFAULT_RFQ_SIZE, LegsUnpriced } from '../constants/rfqs'
import { getRfqGcdAmount } from '../utils/rfqs'
import useIsOptionsPage from './useIsOptionsPage'
import useOptionsOrderTypeQueryParam from './useOptionsOrderTypeQueryParam'
import useSendRfqQueryParams from './useSendRfqQueryParams'

export default function useSelectedOptions(): {
  selectedOptions: SelectedOption[]
  selectOption: (selectedOption: SelectedOption) => void
  deselectOption: (selectedOption: SelectedOption) => void
  toggleSelectOption: (selectedOption: SelectedOption) => void
  getIsOptionSelected: (selectedOption: SelectedOption) => boolean
  getIsInstrumentSelected: (selectedInstrument: string) => boolean
  clearOptions: () => void
} {
  const [rawSelectedOptions, setRawSelectedOptions] = useQueryState(
    QueryParam.SelectedOptions,
    parseAsArrayOf(parseAsString)
  )

  const [orderType] = useOptionsOrderTypeQueryParam()

  const [_0, setSendRfqParams] = useSendRfqQueryParams()

  const selectedOptions = useMemo(() => {
    if (!rawSelectedOptions) {
      return []
    }
    return rawSelectedOptions.map((option) => ({
      instrumentName: option.slice(0, -2).toUpperCase(),
      isBuy: option.endsWith('-B') || option.endsWith('-b'),
    }))
  }, [rawSelectedOptions])

  useEffect(() => {
    // HACK: reset options in state on mount to trigger setRfqQueryParams
    _setSelectedOptions(selectedOptions)
  }, [])

  const _setSelectedOptions = useCallback(
    (selectedOptions: SelectedOption[]) => {
      if (!selectedOptions.length) {
        setRawSelectedOptions(null)

        // reset rfq params
        setSendRfqParams(null)
      } else {
        // todo @stuontheblockchain : messaging around max 4 options
        if (selectedOptions.length >= 5) {
          return
        }
        const formattedOptions = selectedOptions.map(
          (option) => option.instrumentName + (option.isBuy ? '-B' : '-S')
        )
        // deduplicate strings
        setRawSelectedOptions(Array.from(new Set(formattedOptions)))

        setSendRfqParams((params) => {
          const newLegs: LegsUnpriced = selectedOptions.map((selectedOption) => {
            const existingLeg = params.legs.find(
              (leg) =>
                leg.instrument_name === selectedOption.instrumentName &&
                (leg.direction === 'buy') === selectedOption.isBuy
            )
            if (existingLeg) {
              // leg already exists, no-op
              return existingLeg
            } else {
              const amount = getRfqGcdAmount(params.legs)

              return {
                amount: amount > 0 ? amount.toString() : DEFAULT_RFQ_SIZE.toString(),
                direction: selectedOption.isBuy ? 'buy' : 'sell',
                instrument_name: selectedOption.instrumentName,
              }
            }
          })

          return {
            ...params,
            legs: newLegs,
          }
        })
      }
    },
    [setRawSelectedOptions, setSendRfqParams]
  )

  const getIsOptionSelected = useCallback(
    (selectedOption: SelectedOption) => {
      return selectedOptions.some(
        (option) =>
          option.instrumentName === selectedOption.instrumentName &&
          option.isBuy === selectedOption.isBuy
      )
    },
    [selectedOptions]
  )

  const getIsInstrumentSelected = useCallback(
    (selectedInstrument: string) => {
      return selectedOptions.some((option) => option.instrumentName === selectedInstrument)
    },
    [selectedOptions]
  )

  const selectOption = useCallback(
    (selectedOption: SelectedOption) => {
      if (orderType === QuerySpotOrderType.Rfq) {
        // add option

        if (getIsInstrumentSelected(selectedOption.instrumentName)) {
          // instrument already selected for opposite direction
          // flip isBuy: filter out selected option, select same instrument for opposite direction
          _setSelectedOptions([
            ...selectedOptions.filter(
              (option) => option.instrumentName !== selectedOption.instrumentName
            ),
            selectedOption,
          ])
        } else {
          _setSelectedOptions([...selectedOptions, selectedOption])
        }
      } else {
        // set to single option
        _setSelectedOptions([selectedOption])
      }
    },
    [orderType, selectedOptions, _setSelectedOptions, getIsInstrumentSelected]
  )

  const deselectOption = useCallback(
    (selectedOption: SelectedOption) => {
      if (orderType === QuerySpotOrderType.Rfq) {
        const newSelectedOptions = [...selectedOptions].filter(
          (option) =>
            !(
              option.instrumentName === selectedOption.instrumentName &&
              option.isBuy === selectedOption.isBuy
            )
        )
        // remove option from list
        _setSelectedOptions(newSelectedOptions)
      } else {
        // empty options
        _setSelectedOptions([])
      }
    },
    [orderType, selectedOptions, _setSelectedOptions]
  )

  const toggleSelectOption = useCallback(
    (selectedOption: SelectedOption) => {
      if (getIsOptionSelected(selectedOption)) {
        deselectOption(selectedOption)
      } else {
        selectOption(selectedOption)
      }
    },
    [deselectOption, getIsOptionSelected, selectOption]
  )

  const isOptionsPage = useIsOptionsPage()
  useEffect(() => {
    if (!isOptionsPage) {
      setRawSelectedOptions(null)
    }
  }, [isOptionsPage, setRawSelectedOptions])

  const clearOptions = useCallback(() => _setSelectedOptions([]), [_setSelectedOptions])

  return useMemo(
    () => ({
      selectedOptions,
      selectOption,
      deselectOption,
      clearOptions,
      getIsOptionSelected,
      getIsInstrumentSelected,
      toggleSelectOption,
    }),
    [
      clearOptions,
      deselectOption,
      getIsOptionSelected,
      getIsInstrumentSelected,
      selectOption,
      selectedOptions,
      toggleSelectOption,
    ]
  )
}
