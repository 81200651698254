import emptyFunction from '@lyra/core/utils/emptyFunction'
import React, { useEffect, useState } from 'react'

import Input, { InputProps } from '.'

export type NumberInputProps = {
  onChangeValue: (val: number) => void
  value: number
  defaultValue?: number
  formatValue?: (value: number) => string
} & Omit<InputProps, 'value' | 'onChangeValue' | 'placeholder'>

const defaultFormat = (val: number) => val.toString()

export default function NumberInput({
  onChangeValue,
  value,
  // enforce placeholder as a number for safety
  // user will see their defined value
  // or default value in the placeholder
  // reducing risk of accidental defaults
  defaultValue = 0,
  formatValue = defaultFormat,
  onFocus = emptyFunction,
  onBlur = emptyFunction,
  renderAsPlaceholder,
  ...styleProps
}: NumberInputProps) {
  const [rawValue, setRawValue] = useState(value === 0 ? '' : formatValue(value))

  const [isFocused, setIsFocused] = useState(false)
  useEffect(() => {
    if (!isFocused) {
      // sync external state not coming from onChangeValue
      setRawValue(value === 0 ? '' : formatValue(value))
    }
  }, [value, formatValue, isFocused])

  const placeholder = formatValue(defaultValue)

  return (
    <Input
      {...styleProps}
      value={rawValue}
      inputMode="decimal"
      selectTextOnFocus
      status={styleProps.status}
      onChangeValue={(rawValue: string) => {
        const value = parseFloat(rawValue)
        if (rawValue === '') {
          onChangeValue(defaultValue)
          setRawValue(rawValue)
        } else if (
          /^-?\d*(\.\d*)?$/.test(rawValue) &&
          (!isNaN(value) || rawValue === '.' || rawValue === '-')
        ) {
          if (rawValue === '.' || rawValue === '-') {
            onChangeValue(0)
            setRawValue(rawValue)
          } else {
            onChangeValue(value)
            setRawValue(rawValue)
          }
        }
      }}
      onFocus={() => {
        setIsFocused(true)
        setRawValue(value === 0 ? '' : value.toString())
        onFocus()
      }}
      onBlur={() => {
        setIsFocused(false)
        onBlur()
      }}
      placeholder={placeholder}
      renderAsPlaceholder={
        renderAsPlaceholder !== undefined
          ? renderAsPlaceholder && !isFocused
          : value > 0 && defaultValue === value && !isFocused
      }
    />
  )
}
