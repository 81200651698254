import filterNulls from '@lyra/core/utils/filterNulls'
import { parseAsJson, useQueryState } from 'next-usequerystate'
import { useMemo } from 'react'

import { EMPTY_SEND_RFQ_PARAMS, SendRfqParams } from '../constants/rfqs'

const validateSendRfqParams = (_sendRfqParams: SendRfqParams): SendRfqParams => {
  const sendRfqParams: SendRfqParams = {
    ..._sendRfqParams,
    legs: _sendRfqParams.legs
      .filter((leg) => +leg.amount > 0)
      .sort((a, b) => a.instrument_name.localeCompare(b.instrument_name)),
  }
  return sendRfqParams
}

export default function useSendRfqQueryParams(): [
  SendRfqParams,
  (
    value: SendRfqParams | ((old: SendRfqParams) => SendRfqParams | null) | null
  ) => Promise<URLSearchParams>,
] {
  const [_sendRfqParams, setSendRfqParams] = useQueryState<SendRfqParams>(
    'rfq',
    parseAsJson((params) => {
      if (!params || typeof params !== 'object') {
        return null
      }

      const max_total_cost =
        'max_total_cost' in params && typeof params.max_total_cost === 'string'
          ? params.max_total_cost
          : undefined
      const min_total_cost =
        'min_total_cost' in params && typeof params.min_total_cost === 'string'
          ? params.min_total_cost
          : undefined

      const legs =
        'legs' in params && Array.isArray(params['legs'])
          ? filterNulls(
              params.legs.map((leg) => {
                if (typeof leg !== 'object') {
                  return null
                }
                const { amount, direction, instrument_name } = leg
                if (
                  typeof amount === 'string' &&
                  (direction === 'buy' || direction === 'sell') &&
                  typeof instrument_name === 'string'
                ) {
                  return {
                    amount,
                    direction,
                    instrument_name,
                  }
                } else {
                  return null
                }
              })
            )
          : []

      const sendRfqParams: SendRfqParams = {
        legs,
        max_total_cost,
        min_total_cost,
      }

      return validateSendRfqParams(sendRfqParams)
    }).withDefault(EMPTY_SEND_RFQ_PARAMS)
  )

  return useMemo(() => {
    return [validateSendRfqParams(_sendRfqParams), setSendRfqParams]
  }, [_sendRfqParams, setSendRfqParams])
}
