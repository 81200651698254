import { PrivateExecuteQuoteResultSchema } from '@lyra/core/api/types/private.execute_quote'
import { QuoteResultSchema } from '@lyra/core/api/types/private.get_quotes'
import { LegUnpricedSchema, RFQResultPublicSchema } from '@lyra/core/api/types/private.poll_rfqs'
import { PrivateRfqGetBestQuoteResultSchema } from '@lyra/core/api/types/private.rfq_get_best_quote'
import {
  LegPricedSchema,
  PrivateSendQuoteParamsSchema,
} from '@lyra/core/api/types/private.send_quote'
import {
  PrivateSendRfqParamsSchema,
  PrivateSendRfqResultSchema,
} from '@lyra/core/api/types/private.send_rfq'

import { MarketId } from './markets'

// 20% buffer on rfq limit price
export const RFQ_LIMIT_PRICE_BUFFER = 0.2

export const DEFAULT_RFQ_SIZE = 1
export const DEFAULT_RFQ_RATIO = 1

export type Rfq = RFQResultPublicSchema
export type RfqQuote = QuoteResultSchema

export type LegsPriced = LegPricedSchema[]
export type LegsUnpriced = LegUnpricedSchema[]

export type PrivateSendQuoteParamsSchemaNoSignature = Omit<
  PrivateSendQuoteParamsSchema,
  'signature' | 'signature_expiry_sec' | 'nonce' | 'signer'
>

export type RfqVault = {
  label: string
  subaccountId: number
}

export type RfqSendQuoteParams = {
  legs: LegPricedSchema[]
  subaccountId: number
  maxFee: string
  rfqId: string
  baseAssetSubIds: Record<string, string>
  marketId: MarketId
}

export type SendRfqParams = Omit<PrivateSendRfqParamsSchema, 'subaccount_id'>

export type SendRfqResponse = PrivateSendRfqResultSchema

export type ExecuteRfqResponse = PrivateExecuteQuoteResultSchema

export type RfqBestQuote = PrivateRfqGetBestQuoteResultSchema

export const EMPTY_SEND_RFQ_PARAMS: SendRfqParams = {
  legs: [],
}

export enum RfqFilterStatus {
  Open = 'open',
  Filled = 'filled',
  FilledByYou = 'filled-by-you',
  Expired = 'expired',
  Cancelled = 'cancelled',
}
