import React, { Children, cloneElement } from 'react'
import { StackProps, XStack } from 'tamagui'

const ButtonGroup = ({
  children,
  isEqualWidth,
  ...stackProps
}: {
  children: React.ReactNode
  isEqualWidth?: boolean
} & Omit<StackProps, 'space'>): React.JSX.Element => {
  return (
    <XStack {...stackProps} borderRadius="$1">
      {Children.map(children, (child, index) => {
        return child
          ? cloneElement(child, {
              borderTopLeftRadius: index === 0 ? 2 : 0,
              borderBottomLeftRadius: index === 0 ? 2 : 0,
              borderTopRightRadius: index === children.length - 1 ? 2 : 0,
              borderBottomRightRadius: index === children.length - 1 ? 2 : 0,
              flexGrow: isEqualWidth ? 1 : undefined,
              flexShrink: isEqualWidth ? 1 : undefined,
              flexBasis: isEqualWidth ? 0 : undefined,
            })
          : null
      })}
    </XStack>
  )
}

export default ButtonGroup
