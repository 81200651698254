import { LoggerTransaction } from '@lyra/web/constants/logger'
import { Pathname } from '@lyra/web/constants/pages'
import { BoundWalletTransaction } from '@lyra/web/constants/transactions'
import React, { useState } from 'react'

import TransactionButtonRowInternal, {
  TransactionButtonRowProps,
} from './TransactionButtonRowInternal'
import WalletTransactionModal from './WalletTransactionModal'

type Props = {
  reviewTitle: string
  loggerAction: LoggerTransaction
  onSubmitTransaction: BoundWalletTransaction
  onTransactionComplete?: () => void
  completeButtonProps?: {
    label: string
    path: Pathname
  }
} & Omit<TransactionButtonRowProps, 'onSubmitTransaction'>

export default function TransactionButtonRow({
  reviewTitle,
  loggerAction,
  completeButtonProps,
  onSubmitTransaction,
  onTransactionComplete,
  ...txRowProps
}: Props) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <TransactionButtonRowInternal {...txRowProps} onSubmitTransaction={() => setIsOpen(true)} />
      {isOpen ? (
        <WalletTransactionModal
          transaction={onSubmitTransaction}
          onTransactionComplete={onTransactionComplete}
          isAutoExecute
          onClose={() => setIsOpen(false)}
          reviewTitle={reviewTitle}
          loggerAction={loggerAction}
          completeButtonProps={completeButtonProps}
        />
      ) : null}
    </>
  )
}
