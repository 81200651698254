import Notice from '@lyra/core/components/Notice'
import Section from '@lyra/core/components/Section'
import useSubaccount from '@lyra/web/hooks/useSubaccount'
import useTransaction from '@lyra/web/hooks/useTransaction'
import { useMemo } from 'react'
import { StackProps } from 'tamagui'

import DepositButton from './DepositButton'
import TransferButton from './TransferButton'

export default function FundTradingButtonRow({
  message,
  ...props
}: { message?: string | null } & StackProps) {
  const { balances } = useTransaction()
  const { subaccount, subaccounts } = useSubaccount()

  // funding wallet and subaccounts
  const nonZeroBalance = useMemo(() => {
    return (
      Object.values(balances).some((bal) => bal > BigInt(0)) ||
      Object.values(subaccounts).some((sub) => !!sub.collaterals.length)
    )
  }, [balances, subaccounts])

  const showTransfer = nonZeroBalance

  return (
    <Section.YStack {...props}>
      {message ? <Notice message={message} /> : null}
      {showTransfer ? (
        <TransferButton
          defaultFromSubaccountId="funding"
          defaultToSubaccountId={subaccount?.subaccount_id}
          width="100%"
          size="lg"
          isCta
          label="Transfer Funds To Continue"
        />
      ) : (
        <DepositButton label="Deposit Funds" width="100%" size="lg" isCta />
      )}
    </Section.YStack>
  )
}
