import { LyraAuthHeaders } from '@lyra/core/constants/api'

import tryRequest, { RequestOptions } from '../tryRequest'
import { PrivateOrderQuoteParamsSchema } from '../types/private.order_quote'
import { PrivateOrderQuoteResponseSchema } from '../types/private.order_quote'

export default async function fetchOrderQuote(
  params: PrivateOrderQuoteParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
): Promise<PrivateOrderQuoteResponseSchema> {
  return tryRequest<PrivateOrderQuoteParamsSchema, PrivateOrderQuoteResponseSchema>(
    '/private/order_quote',
    params,
    { ...options, authHeaders }
  )
}
