import { RpcError } from '@lyra/core/api/error'

import { MarketId } from '../constants/markets'
import { MIN_TRADE_SIZE } from '../constants/order'
import { getTransactionDisabledMessage } from './wallet'

type ErrorMessageParams = {
  marketId: MarketId
}

export const formatErrorMessage = (error: RpcError, options: ErrorMessageParams): string => {
  switch (error.code) {
    case 11012:
      return `Minimum order size of ${MIN_TRADE_SIZE[options.marketId]}`
    case 16000:
      return getTransactionDisabledMessage('geoblocked')
    default:
      return error.message
  }
}
