import { OrderType1, TriggerType1 } from '@lyra/core/api/types/private.order'
import { InvalidReason } from '@lyra/core/api/types/private.order_quote'
import { SECONDS_IN_DAY } from '@lyra/core/constants/time'
import formatNumber from '@lyra/core/utils/formatNumber'
import formatUSD from '@lyra/core/utils/formatUSD'

import { InstrumentType, Ticker } from '../constants/instruments'
import { MARK_PRICE_FEE_RATE_CAP, OrderType, TimeInForce } from '../constants/order'
import { Position } from '../constants/position'
import { Subaccount } from '../constants/subaccount'
import {
  getBestAskPrice,
  getBestBidPrice,
  parseInstrumentName,
  parseOptionFromInstrumentName,
} from './instruments'
import { countDecimals } from './number'
import { getUtcNowSecs } from './time'

// TODO: @earthtojake rename getBestPrice
export const getDefaultLimitPrice = (ticker: Ticker, isBuy: boolean) => {
  const bidPrice = ticker.best_bid_price ? +ticker.best_bid_price : undefined
  const askPrice = ticker.best_ask_price ? +ticker.best_ask_price : undefined

  return isBuy ? askPrice : bidPrice
}

// TODO: @earthtojake rename getMinMaxPrice
export const getDefaultMarketPrice = (ticker: Ticker, isBuy: boolean) => {
  const minPrice = +ticker.min_price
  const maxPrice = +ticker.max_price

  return isBuy ? maxPrice : minPrice
}

// TODO: @earthtojake rename getBestOrMinMaxPrice
export const getLimitPrice = (ticker: Ticker, isBuy: boolean) => {
  const bidPrice = +ticker.best_bid_price !== 0 ? +ticker.best_bid_price : +ticker.min_price
  const askPrice = +ticker.best_ask_price !== 0 ? +ticker.best_ask_price : +ticker.max_price
  return isBuy ? askPrice : bidPrice
}

export const getOrderSignatureExpirySecs = (
  instrumentName: string,
  orderType: OrderType,
  timeInForce: TimeInForce,
  _expirySecs: number
) => {
  // market, ioc and foc orders always have 1d expiry
  const expirySecs =
    orderType === OrderType.Market
      ? SECONDS_IN_DAY
      : timeInForce === TimeInForce.IoC || timeInForce === TimeInForce.FillOrKill
      ? SECONDS_IN_DAY
      : _expirySecs

  let signatureExpirySecs = getUtcNowSecs() + expirySecs

  const option = parseOptionFromInstrumentName(instrumentName)
  if (option) {
    // ensure expiry secs is less than option expiry
    const optionExpiryUtcSecs = Math.floor(option.expiry.getTime() / 1000)
    signatureExpirySecs = Math.min(signatureExpirySecs, optionExpiryUtcSecs - 1)
  }

  return signatureExpirySecs
}

export const getOptionProjectedSettlePnl = (
  isLong: boolean,
  isCall: boolean,
  strikePrice: number,
  spotPriceAtExpiry: number,
  pricePerOption: number,
  size: number,
  liquidationPrice?: number | null
): number => {
  if (isLong) {
    if (isCall) {
      // Long call
      return (
        (spotPriceAtExpiry >= strikePrice
          ? // ITM
            spotPriceAtExpiry - strikePrice - pricePerOption
          : // OTM
            pricePerOption * -1) * size
      )
    } else {
      // Long put
      return (
        (spotPriceAtExpiry <= strikePrice
          ? // ITM
            strikePrice - spotPriceAtExpiry - pricePerOption
          : // OTM
            pricePerOption * -1) * size
      )
    }
  } else {
    if (isCall) {
      return (
        (liquidationPrice && spotPriceAtExpiry >= liquidationPrice
          ? pricePerOption - spotPriceAtExpiry // Liquidation (max loss)
          : spotPriceAtExpiry <= strikePrice
          ? // OTM
            pricePerOption
          : // ITM
            pricePerOption - spotPriceAtExpiry + strikePrice) * size
      )
    } else {
      // Cash secured put
      return (
        (liquidationPrice && spotPriceAtExpiry <= liquidationPrice
          ? pricePerOption - strikePrice // Liquidation (max loss)
          : spotPriceAtExpiry <= strikePrice
          ? // ITM
            spotPriceAtExpiry - strikePrice + pricePerOption
          : // OTM
            pricePerOption) * size
      )
    }
  }
}

export const getOptionMaxProfit = (isBuy: boolean, size: number, ticker: Ticker): number => {
  const isCall = ticker.option_details.option_type === 'C'
  const basePrice = isBuy ? getBestAskPrice(ticker) : getBestBidPrice(ticker)
  const premium = basePrice ? size * basePrice : 0
  if (isCall && isBuy) {
    return Number.MAX_VALUE
  } else if (isCall && !isBuy) {
    return premium
  } else if (!isCall && isBuy) {
    return +ticker.option_details.strike - premium
  } else {
    return premium
  }
}

export const getOptionMaxLoss = (isBuy: boolean, size: number, ticker: Ticker): number => {
  const isCall = ticker.option_details.option_type === 'C'
  const basePrice = isBuy ? getBestAskPrice(ticker) : getBestBidPrice(ticker)
  const premium = basePrice ? size * basePrice : 0
  if (isCall && isBuy) {
    return premium
  } else if (isCall && !isBuy) {
    return Number.MAX_VALUE
  } else if (!isCall && isBuy) {
    return premium
  } else {
    return +ticker.option_details.strike * size - premium
  }
}

export const getOptionBreakEvenPrice = (
  isCall: boolean,
  strikePrice: number,
  optionPrice: number,
  isBaseCollateral?: boolean
): number => {
  return isCall && !isBaseCollateral ? strikePrice + optionPrice : strikePrice - optionPrice
}

type EstTradeFeeParams = Pick<Ticker, 'instrument_name' | 'index_price'> & {
  taker_fee_rate: string
  mark_price: string
  base_fee: string
}

export const getEstTradeFee = (
  { instrument_name, index_price, taker_fee_rate, mark_price, base_fee }: EstTradeFeeParams,
  size: number
): number => {
  const parsedInstrument = parseInstrumentName(instrument_name)
  if (!parsedInstrument) {
    return 0
  }
  if (parsedInstrument.type === InstrumentType.Perps) {
    return +index_price * +taker_fee_rate * size + +base_fee
  } else {
    return (
      Math.min(+mark_price * MARK_PRICE_FEE_RATE_CAP, +index_price * +taker_fee_rate) * size +
      +base_fee
    )
  }
}

export const getInvalidReasonWarning = (invalidReason: InvalidReason): string | null => {
  switch (invalidReason) {
    case 'Account is currently under maintenance margin requirements, trading is frozen.':
      return 'Your account is under maintenance margin requirements, trading is frozen.'
    case 'Consider canceling other limit orders or using IOC, FOK, or market orders. This order is risk-reducing, but if filled with other open orders, buying power might be insufficient.':
      return 'This order is risk-reducing, but if filled with other open orders, buying power might be insufficient. Consider canceling other limit orders or using IOC, FOK, or market orders.'
    case 'Insufficient buying power, consider reducing order size or canceling other orders.':
      return 'Insufficient balance, consider reducing order size or canceling other orders.'
    case 'Insufficient buying power, only a single risk-reducing open order is allowed.':
      return 'Insufficient balance, only orders that reduce margin requirements are allowed.'
    case 'This order would cause account to fall under maintenance margin requirements.':
      return 'This order would cause your account to fall under maintenance margin requirements.'
    default:
      return null
  }
}

export const getTriggerPriceSlippageForLimitPrice = (
  isBuy: boolean,
  limitPrice: number,
  triggerPrice: number
): number => {
  // buy = limit price > trigger price
  // sell = limit price < trigger price
  if (triggerPrice === 0 || limitPrice === 0) {
    return 0
  }
  return ((isBuy ? 1 : -1) * (limitPrice - triggerPrice)) / triggerPrice
}

export const getLimitPriceForTriggerPriceSlippage = (
  isBuy: boolean,
  triggerPrice: number,
  triggerPriceSlippage: number
): number => {
  // buy = limit price > trigger price, positive slippage
  // sell = limit price < trigger price, negative slippage
  if (!triggerPrice) {
    return 0
  }
  const factor = isBuy ? 1 + triggerPriceSlippage : 1 - triggerPriceSlippage
  return triggerPrice * factor
}

export const formatOrderType = (
  orderType: OrderType | OrderType1,
  triggerType?: TriggerType1,
  abbreviate?: boolean
): string => {
  if (triggerType === 'stoploss') {
    return abbreviate ? 'SL' : 'Stop Loss'
  } else if (triggerType === 'takeprofit') {
    return abbreviate ? 'TP' : 'Take Profit'
  } else if (orderType === 'limit') {
    return 'Limit'
  } else {
    return 'Market'
  }
}

export const formatOrderPrice = (amount: number | string, tickSize: number | string) => {
  const dps = countDecimals(+tickSize)
  return formatUSD(amount, { dps, showCommas: false })
}

export const formatOrderSize = (amount: number | string, amountStep: number | string) => {
  const dps = countDecimals(+amountStep)
  return formatNumber(Math.abs(+amount), { dps, showCommas: false })
}

export function formatTickSize(num: number): string {
  const decimalPlaces = countDecimals(num)
  return num.toFixed(decimalPlaces)
}

export const getLeverageFromSize = (
  subaccount: Subaccount,
  openPosition: Position | undefined,
  tradeSize: number,
  spotPrice: number
) => {
  if (tradeSize === 0) {
    const positionSize = openPosition ? +openPosition.amount : 0
    const positionLeverage =
      openPosition && openPosition.leverage
        ? +openPosition.leverage * (positionSize >= 0 ? 1 : -1)
        : 0
    return positionLeverage
  }

  // subtract options maintenance margin
  const optionsMaintenanceMargin = subaccount.positions
    .filter((position) => position.instrument_type === 'option')
    .reduce((sum, position) => sum + +position.maintenance_margin, 0)

  // add unrealized pnl
  const perpsUnrealizedPnl = subaccount.positions
    .filter((position) => position.instrument_type === 'perp')
    .reduce((sum, position) => sum + +position.mark_value, 0)

  const perpsCollateral =
    +subaccount.collaterals_value + optionsMaintenanceMargin + perpsUnrealizedPnl

  const positionSize = openPosition ? +openPosition.amount : 0
  const postTradePositionSize = tradeSize + positionSize

  // leverage can be negative
  return perpsCollateral > 0 ? (postTradePositionSize * spotPrice) / perpsCollateral : 0
}

export const getSizeFromLeverage = (
  subaccount: Subaccount,
  openPosition: Position | undefined,
  leverage: number,
  spotPrice: number
) => {
  // subtract options maintenance margin
  const optionsMaintenanceMargin = subaccount.positions
    .filter((position) => position.instrument_type === 'option')
    .reduce((sum, position) => sum + +position.maintenance_margin, 0)

  // add unrealized pnl
  const perpsUnrealizedPnl = subaccount.positions
    .filter((position) => position.instrument_type === 'perp')
    .reduce((sum, position) => sum + +position.mark_value, 0)

  const perpsCollateral =
    +subaccount.collaterals_value + optionsMaintenanceMargin + perpsUnrealizedPnl

  const targetSizeFromLeverage = spotPrice > 0 ? (leverage * perpsCollateral) / spotPrice : 0

  const positionSize = openPosition ? +openPosition.amount : 0

  const sizeFromLeverage = targetSizeFromLeverage - positionSize // offset current position

  // size can be negative
  return sizeFromLeverage
}
