import dateFormat from 'dateformat'

import parseDate from './parseDate'

export type FormatDateTimeOptions = {
  hideYear?: boolean
  hideMins?: boolean
  includeTimezone?: boolean
  customFormat?: string
  utc?: boolean
}

export default function formatDateTime(ts: number | Date, options?: FormatDateTimeOptions): string {
  const { hideYear = false, hideMins = true, includeTimezone = false, customFormat } = options ?? {}

  return dateFormat(
    parseDate(ts),
    `${
      customFormat
        ? customFormat
        : `h${hideMins ? '' : ':MM'}tt, mmm d ${!hideYear ? 'yyyy' : ''} ${
            includeTimezone ? ' Z' : ''
          }`
    }`,
    options?.utc
  )
}
