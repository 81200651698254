import { LegUnpricedSchema, RFQResultPublicSchema } from '@lyra/core/api/types/private.poll_rfqs'
import { LegPricedSchema } from '@lyra/core/api/types/private.send_quote'
import { Ticker } from '@lyra/web/constants/instruments'
import {
  LegsPriced,
  Rfq,
  RFQ_LIMIT_PRICE_BUFFER,
  RfqBestQuote,
  RfqQuote,
  SendRfqParams,
} from '@lyra/web/constants/rfqs'

import { formatInstrument, formatInstrumentExpiry, getBestOrMinOrMaxPrice } from './instruments'
import { countDecimals } from './number'
import { getDefaultLimitPrice } from './order'

function getGcd(...numbers: number[]): number {
  if (numbers.length === 0) {
    return 0
  }

  const scaleFactor = (num: number) => {
    const decimalPlaces = countDecimals(num)
    return Math.pow(10, decimalPlaces)
  }

  const gcd = (a: number, b: number): number => {
    while (b !== 0) {
      const temp = b
      b = a % b
      a = temp
    }
    return a
  }

  const lcmFactor = numbers.reduce((acc, num) => Math.max(acc, scaleFactor(num)), 1)
  const scaledNumbers = numbers.map((num) => num * lcmFactor)

  const resultGcd = scaledNumbers.reduce((acc, num) => gcd(acc, num))

  return resultGcd / lcmFactor
}

export const getRfqGcdAmount = (legs: LegUnpricedSchema[]): number => {
  if (legs.length === 0) {
    return 0
  }
  if (legs.length === 1) {
    return +legs[0].amount
  }
  return getGcd(...legs.map((leg) => +leg.amount))
}

export function getRfqSize(rfq: RFQResultPublicSchema): number {
  if (rfq.legs.length === 0) {
    return 0
  }
  if (rfq.legs.length === 1) {
    return +rfq.legs[0].amount
  }
  return getGcd(...rfq.legs.map((leg) => +leg.amount))
}

export function getQuoteRfqSize(rfq: RfqQuote): number {
  if (rfq.legs.length === 0) {
    return 0
  }
  if (rfq.legs.length === 1) {
    return +rfq.legs[0].amount
  }
  return getGcd(...rfq.legs.map((leg) => +leg.amount))
}

export function formatLegPriced(leg: LegUnpricedSchema): string | undefined {
  return `${leg.amount}x ${leg.direction === 'buy' ? 'Long' : 'Short'} ${formatInstrument(
    leg.instrument_name
  )}, ${formatInstrumentExpiry(leg.instrument_name)}`
}

export function getLegsPricedTotalCost(legs: LegPricedSchema[]): number {
  return legs.reduce(
    (acc, curr) => (acc += (curr.direction === 'sell' ? +curr.price : -+curr.price) * +curr.amount),
    0
  )
}

export function getDefaultLegsPricedForRfqQuote(
  legs: LegUnpricedSchema[],
  tickers: Record<string, Ticker>
): LegsPriced {
  return legs.map((leg) => {
    return {
      ...leg,
      price: tickers[leg.instrument_name].mark_price,
    }
  })
}

export function getLegsPricedForRfqQuote(
  legs: LegUnpricedSchema[],
  markPrice: number,
  totalCost: number,
  tickers: Record<string, Ticker>
): LegsPriced {
  const defaultLegsPriced = getDefaultLegsPricedForRfqQuote(legs, tickers)
  const totalCostToMarkRatio = Math.abs(totalCost / markPrice)
  return defaultLegsPriced.map((leg) => {
    return {
      ...leg,
      price: (+leg.price * totalCostToMarkRatio).toString(),
    }
  })
}

export function getBaseAssetSubIdsForRfq(
  rfq: Rfq,
  tickers: Record<string, Ticker>
): Record<string, string> {
  return rfq.legs.reduce(
    (map, leg) => {
      const ticker = tickers[leg.instrument_name]
      if (ticker) {
        return {
          ...map,
          [leg.instrument_name]: ticker.base_asset_sub_id,
        }
      }
      return map
    },
    {} as Record<string, string>
  )
}

export function formatOpenRfqTimestamp(timestamp: number): string {
  const diffMs = new Date().getTime() - timestamp
  const totalSeconds = Math.floor(diffMs / 1000)
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds % 60
  return minutes > 0 ? `${minutes}m ${seconds}s ago` : `${seconds}s ago`
}

// summation of limit price for legs of an option
// opposite dir is all summation of flipped legs
const getLegCost = (
  tickers: Record<string, Ticker>,
  legs: LegUnpricedSchema[],
  oppositeDir: boolean
): number => {
  return legs.reduce((acc, leg) => {
    const limitPrice = getBestOrMinOrMaxPrice(
      tickers[leg.instrument_name],
      !oppositeDir ? leg.direction === 'buy' : leg.direction !== 'buy'
    )
    return (
      acc +
      (!oppositeDir
        ? leg.direction === 'buy'
          ? limitPrice
          : -limitPrice
        : leg.direction === 'buy'
        ? -limitPrice
        : limitPrice) *
        +leg.amount
    )
  }, 0)
}

// get fair value of options
export const getRfqMarkValue = (
  tickers: Record<string, Ticker>,
  legs: LegUnpricedSchema[]
): number => {
  const markPrice = legs.reduce((acc, leg) => {
    if (!tickers[leg.instrument_name]) {
      console.warn('missing ticker for instrument_name', leg.instrument_name, tickers)
      return acc
    }

    return (
      acc +
      (leg.direction === 'buy'
        ? +tickers[leg.instrument_name].mark_price
        : -+tickers[leg.instrument_name].mark_price) *
        +leg.amount
    )
  }, 0)
  return markPrice
}

export const getRfqBestOfferDEPRECATED = (
  tickers: Record<string, Ticker>,
  legs: LegUnpricedSchema[],
  isBuy: boolean
): number => {
  const askPrice = !isBuy ? getLegCost(tickers, legs, true) : getLegCost(tickers, legs, false)
  const bidPrice = isBuy ? getLegCost(tickers, legs, true) : getLegCost(tickers, legs, false)

  return isBuy ? askPrice : bidPrice
}

export const getRfqBestBidAskOffer = (
  tickers: Record<string, Ticker>,
  legs: LegUnpricedSchema[],
  isBuy: boolean // direction of rfq order
) => {
  const price = legs.reduce((acc, leg) => {
    if (acc === -1) {
      return -1
    }

    if (!tickers[leg.instrument_name]) {
      console.warn('missing ticker for instrument_name', leg.instrument_name, tickers)
      return -1
    }

    const isLegBug = isBuy ? leg.direction === 'buy' : leg.direction === 'sell'

    const limitPrice = getDefaultLimitPrice(tickers[leg.instrument_name], isLegBug)

    if (!limitPrice) {
      // invalid since 1 leg has no bid/ask offer
      return -1
    }

    return acc + (isLegBug ? limitPrice : -limitPrice) * +leg.amount
  }, 0)

  return price !== -1 ? price : undefined
}

export const getRfqMaxTotalCost = (
  sendRfqParams: SendRfqParams,
  quote: RfqBestQuote,
  indexPrice: number
) => {
  const estTotalCost = +quote.estimated_total_cost

  const totalAmount = sendRfqParams.legs.reduce((sum, l) => sum + Math.abs(+l.amount), 0)
  // Note: to ensure safe buffers for zero cost spreads, we use a separate formula
  // https://discord.com/channels/808292126818304041/1281439279607844917/1283203605490569347
  const zeroCostPrice = totalAmount * 0.01 * indexPrice

  const maxTotalCost = estTotalCost + Math.max(estTotalCost * RFQ_LIMIT_PRICE_BUFFER, zeroCostPrice)

  return maxTotalCost
}
