import { BoundWalletTransaction } from '@lyra/web/constants/transactions'
import useTransaction from '@lyra/web/hooks/useTransaction'
import { useCallback } from 'react'
import { StackProps } from 'tamagui'

import TransactionButtonRow from './TransactionButtonRow'

export default function EnableTradingButtonRow({ ...props }: StackProps) {
  const { tryEnableTradingAccount } = useTransaction()

  const tryEnableTradingAccountWrapped: BoundWalletTransaction = useCallback(
    async (options) => {
      return await tryEnableTradingAccount(options)
    },
    [tryEnableTradingAccount]
  )

  return (
    <TransactionButtonRow
      {...props}
      label="Enable Trading"
      onSubmitTransaction={tryEnableTradingAccountWrapped}
      loggerAction="enable-account"
      reviewTitle="Enable Trading"
    />
  )
}
