import { useContext, useEffect, useMemo } from 'react'

import { Ticker } from '../constants/instruments'
import { TickerContext } from '../providers/TickerProvider'

export default function useTicker(instrumentName: string | null | undefined): Ticker | undefined {
  const { isReady, subscribeTickers, unsubscribeTickers, tickers } = useContext(TickerContext)

  useEffect(() => {
    if (isReady && instrumentName) {
      subscribeTickers([instrumentName])
    }
    return () => {
      if (isReady && instrumentName) {
        unsubscribeTickers([instrumentName])
      }
    }
  }, [isReady, subscribeTickers, unsubscribeTickers, instrumentName])

  return useMemo(
    () => (instrumentName ? tickers[instrumentName] : undefined),
    [tickers, instrumentName]
  )
}
