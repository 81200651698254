import { RpcError } from '@lyra/core/api/error'
import fetchOrderQuote from '@lyra/core/api/private/fetchOrderQuote'
import {
  Direction,
  TriggerPrice,
  TriggerPriceType,
  TriggerType,
} from '@lyra/core/api/types/private.order'
import { LyraAuthHeaders } from '@lyra/core/constants/api'
import { SECONDS_IN_DAY } from '@lyra/core/constants/time'
import {
  MAX_FEE_MULTIPLIER,
  OPTIONS_TAKER_FEES,
  PERPETUAL_FUTURES_TAKER_FEES,
} from '@lyra/web/constants/order'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { MarketId } from '../constants/markets'
import { MAX_ORDER_EXPIRY_SECS, OrderParamsWithoutSignature, Quote } from '../constants/order'
import { OrderType, TimeInForce } from '../constants/order'
import { signOrder } from '../utils/actions'
import { formatErrorMessage } from '../utils/error'
import { parseOptionFromInstrumentName } from '../utils/instruments'
import { getMarketId } from '../utils/markets'
import { getUtcNowSecs } from '../utils/time'
import useAuth from './useAuth'
import useSubaccount from './useSubaccount'
import useTicker from './useTicker'
import useTransaction from './useTransaction'

export type QuoteHookParams = {
  marketId: MarketId
  instrumentName: string
  isBuy: boolean
  size: number
  limitPrice: number
  triggerPrice?: TriggerPrice
  triggerPriceType?: TriggerPriceType
  triggerType?: TriggerType
  reduceOnly?: boolean
} & (
  | {
      orderType: OrderType.Limit
      // limit order specific params
      timeInForce: TimeInForce // user-defined order behaviour
      expirySecs: number // user-defined order expiry
    }
  | {
      orderType: OrderType.Market
      timeInForce?: undefined
      expirySecs?: undefined
    }
)

export type QuoteState = {
  isLoading: boolean
  error: string | undefined
  quote: Quote | undefined
  quoteParams: OrderParamsWithoutSignature | undefined
  orderParams: OrderParamsWithoutSignature | undefined
}

// IMPORTANT!!
// do not increase debounce above 100ms
// debounce will cause deviation from trade inputs and the quote
const DEBOUNCE_MS = 100

const getSignatureExpirySecs = (
  instrumentName: string,
  timeInForce: TimeInForce,
  expirySecs: number
) => {
  let signatureExpirySecs = getUtcNowSecs() + expirySecs
  if (timeInForce === TimeInForce.IoC || timeInForce === TimeInForce.FillOrKill) {
    signatureExpirySecs = getUtcNowSecs() + SECONDS_IN_DAY
  }
  const option = parseOptionFromInstrumentName(instrumentName)
  if (option) {
    const optionExpiryUtcSecs = Math.floor(option.expiry.getTime() / 1000)
    signatureExpirySecs = Math.min(signatureExpirySecs, optionExpiryUtcSecs - 1)
  }
  return signatureExpirySecs
}

export default function useQuoteOrder(params: QuoteHookParams | null) {
  const { user } = useAuth()
  const { sessionKey } = useTransaction()
  const { subaccount } = useSubaccount()
  const ticker = useTicker(params ? params.instrumentName : null)

  const [quote, setQuote] = useState<QuoteState>({
    isLoading: false,
    error: undefined,
    quote: undefined,
    quoteParams: undefined,
    orderParams: undefined,
  })

  const subaccountId = subaccount?.subaccount_id
  const authSignature = user?.hasAuth ? user.auth.signature : undefined
  const authTimestamp = user?.hasAuth ? user.auth.timestamp : undefined
  const address = user?.address // wallet address
  const baseAssetSubId = ticker?.base_asset_sub_id
  const indexPrice = ticker ? +ticker.index_price : undefined

  const instrumentType = ticker?.instrument_type

  const prevParams = useRef(params)
  const timeoutRef = useRef<NodeJS.Timeout>()
  const quoteAsync = useCallback(
    (params: QuoteHookParams) => {
      const {
        instrumentName,
        orderType,
        isBuy,
        size,
        limitPrice,
        timeInForce: inputTimeInForce,
        expirySecs: inputExpirySecs,
        triggerPrice,
        triggerPriceType,
        triggerType,
        reduceOnly,
      } = params

      // clear any previous debounced quotes
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      let isParamUpdate = false
      if (!prevParams.current) {
        // no params previously set, definitely an update
        isParamUpdate = true
      } else {
        // ignore updates for limit prices
        const { limitPrice: _0, ...prevParamsWithoutLimitPrice } = prevParams.current
        const { limitPrice: _1, ...paramsWithoutLimitPrice } = params
        isParamUpdate =
          JSON.stringify(prevParamsWithoutLimitPrice) !== JSON.stringify(paramsWithoutLimitPrice)
      }

      prevParams.current = params

      const timeInForce =
        orderType === OrderType.Limit
          ? inputTimeInForce
          : orderType === OrderType.Market
          ? TimeInForce.GoodTilCancelled
          : undefined

      const expirySecs =
        orderType === OrderType.Limit
          ? Math.min(inputExpirySecs, MAX_ORDER_EXPIRY_SECS)
          : orderType === OrderType.Market
          ? SECONDS_IN_DAY
          : undefined
      const marketId = getMarketId(ticker?.base_currency ?? '')
      if (
        !baseAssetSubId ||
        !marketId ||
        !instrumentType ||
        !indexPrice ||
        !sessionKey ||
        !subaccountId ||
        !authSignature ||
        !authTimestamp ||
        !address ||
        !size ||
        !timeInForce ||
        !expirySecs
      ) {
        // not ready to trade, do not trigger loading state
        setQuote({
          isLoading: false,
          error: undefined,
          quote: undefined,
          quoteParams: undefined,
          orderParams: undefined,
        })
        return undefined
      }

      const max_fee = (
        indexPrice *
        (instrumentType === 'option'
          ? OPTIONS_TAKER_FEES.TIER_11
          : PERPETUAL_FUTURES_TAKER_FEES.TIER_11) *
        MAX_FEE_MULTIPLIER[marketId]
      ).toString()

      const direction: Direction = isBuy ? 'buy' : 'sell'

      const quoteParams: OrderParamsWithoutSignature = {
        amount: size.toString(),
        direction,
        instrument_name: instrumentName,
        limit_price: limitPrice.toString(),
        max_fee: max_fee,
        order_type: orderType,
        subaccount_id: subaccountId,
        time_in_force: timeInForce,
        signature_expiry_sec: getSignatureExpirySecs(instrumentName, timeInForce, expirySecs),
        trigger_price: triggerPrice,
        trigger_price_type: triggerPriceType,
        trigger_type: triggerType,
        reduce_only: reduceOnly,
      }

      // IMPORTANT!!
      // reset quote on trade param changes
      // removing this line will cause deviation from trade form info and the order button
      // for ticker updates, we maintain the current quote and update in the background
      if (isParamUpdate) {
        setQuote({
          isLoading: true,
          error: undefined,
          quote: undefined,
          quoteParams,
          orderParams: undefined,
        })
      }

      const authHeaders: LyraAuthHeaders = {
        'X-LyraSignature': authSignature,
        'X-LyraTimestamp': authTimestamp,
        'X-LyraWallet': address,
      }

      timeoutRef.current = setTimeout(async () => {
        try {
          const signedQuoteParams = await signOrder(
            address,
            sessionKey,
            baseAssetSubId,
            ticker.base_asset_address,
            quoteParams
          )
          const { result: quote } = await fetchOrderQuote(signedQuoteParams, authHeaders)

          // use suggested max fee from orderbook for order
          // Note: to avoid bad bigint conversions we need to limit to 2dp
          const suggestedMaxFee = parseFloat(quote.suggested_max_fee).toFixed(2)
          const orderMaxFee =
            parseFloat(suggestedMaxFee) > 0 ? suggestedMaxFee : parseFloat(max_fee).toFixed(2)
          const orderParams = quote.is_valid
            ? {
                ...quoteParams,
                max_fee: orderMaxFee,
              }
            : undefined

          setQuote({ isLoading: false, error: undefined, quote, quoteParams, orderParams })
          return quote
        } catch (error) {
          let errorMessage = 'Something went wrong. Unable to submit order.'
          if (error instanceof RpcError) {
            errorMessage = formatErrorMessage(error, { marketId: params.marketId })
          } else {
            console.error(error)
          }
          setQuote({
            isLoading: false,
            error: errorMessage,
            quote: undefined,
            quoteParams: undefined,
            orderParams: undefined,
          })
          return undefined
        }
      }, DEBOUNCE_MS)
    },
    // Note: these dependencies must all be primitives: string, number, boolean, null, undefined
    [
      ticker?.base_currency,
      ticker?.base_asset_address,
      baseAssetSubId,
      instrumentType,
      indexPrice,
      sessionKey,
      subaccountId,
      authSignature,
      authTimestamp,
      address,
    ]
  )

  useEffect(() => {
    if (params) {
      quoteAsync(params)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(params), quoteAsync])

  return useMemo(() => ({ ...quote, quoteAsync }), [quote, quoteAsync])
}
