import { parseAsStringEnum, useQueryState } from 'next-usequerystate'

import { QueryParam, QuerySpotOrderType } from '../constants/query'

export default function useOptionsOrderTypeQueryParam(): [
  QuerySpotOrderType,
  (orderType: QuerySpotOrderType) => void,
] {
  return useQueryState(
    QueryParam.OrderType,
    parseAsStringEnum(Object.values(QuerySpotOrderType)).withDefault(QuerySpotOrderType.Limit)
  )
}
